/* eslint-disable */

/***************************/
/* Importing vue,          */
/* changing productionTip, */
/* general mount function  */
/***************************/
import { createApp } from 'vue'

const app = createApp({})

export { app }

import { axios } from './axiosConfig'
app.axios = axios

import mitt from 'mitt'
app.mitt = mitt()

import historyInstance from './history'
app.history = historyInstance

import { initOverlayStatus } from '@shared/Overlay/overlay-status-mixin'
initOverlayStatus()

import toastInstance from '@shared/Toast/Toast.vue'
app.toast = toastInstance

import VueSimpleAlert from 'vue3-simple-alert'
app.use(VueSimpleAlert)

import { setupErrorLogging } from './errorHandler'
setupErrorLogging()

/*********************/
/* Importing styling */
/*********************/
import '@/tailwind.css'
import '@/global.scss'

import { defineAsyncComponent } from 'vue'
/************************/
/* Importing components */
/************************/
// Frontpage
const FrontpageView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "3-frontpage" */ '@components/Views/FrontpageView/FrontpageView.vue'
  )
)
const LicensePlateLookup = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "3-frontpage" */ '@components/LicensePlateLookup/LicensePlateLookup.vue'
  )
)
const Search = defineAsyncComponent(() =>
  import(/* webpackChunkName: "3-frontpage" */ '@components/Search/Search.vue')
)
const ArticleContainerEventHook = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "3-frontpage" */ '@components/ArticleContainerEventHook/ArticleContainerEventHook.vue'
  )
)

// Ad
const AdView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/Views/AdView/AdView.vue'
  )
)
const SubHeaderAd = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/SubHeaderAd/SubHeaderAd.vue'
  )
)
const SubHeaderCompany = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/SubHeaderCompany/SubHeaderCompany.vue'
  )
)
const Gallery = defineAsyncComponent(() =>
  import(/* webpackChunkName: "1-detail" */ '@components/Gallery/Gallery.vue')
)
const Gallery2 = defineAsyncComponent(() =>
  import(/* webpackChunkName: "1-detail" */ '@components/Gallery/Gallery2.vue')
)
const GalleryActions = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/GalleryContainer/GalleryActions.vue'
  )
)
const ContactButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/Contact/ContactButton/ContactButton.vue'
  )
)
const ContactEventHook = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/ContactEventHook/ContactEventHook.vue'
  )
)
const AdEventHook = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/AdEventHook/AdEventHook.vue'
  )
)
const FinanceSantander = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/FinanceSantander/FinanceSantander.vue'
  )
)
const FinanceToyota = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/FinanceToyota/FinanceToyota.vue'
  )
)
const AdPriceFinanceButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/AdPrice/AdPriceFinanceButton/AdPriceFinanceButton.vue'
  )
)
const AdPriceInsuranceButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/AdPrice/AdPriceInsuranceButton/AdPriceInsuranceButton.vue'
  )
)
const ExchangePriceWidget = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/ExchangePriceWidget/ExchangePriceWidget.vue'
  )
)
const OnlineSalesButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/OnlineSales/OnlineSalesButton.vue'
  )
)
const InsuranceSamlino = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/InsuranceSamlino/InsuranceSamlino.vue'
  )
)
const InsuranceCodan = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/InsuranceCodan/InsuranceCodan.vue'
  )
)
const InsuranceCodanExpanded = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/InsuranceCodanExpanded/InsuranceCodanExpanded.vue'
  )
)

const InsuranceTechno = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/InsuranceTechno/InsuranceTechno.vue'
  )
)
const InsuranceSemler = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/InsuranceSemler/InsuranceSemler.vue'
  )
)
const AccordionContentContactButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/AccordionPanel/AccordionContentContactButton/AccordionContentContactButton.vue'
  )
)
const Description = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "1-detail" */ '@components/Description/Description.vue'
  )
)

// // Private Ad
const PrivateAdView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "2-private-ad" */ '@components/Views/PrivateAdView/PrivateAdView.vue'
  )
)
const PrivateAdFlow = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "2-private-ad" */ '@components/PrivateAdFlow/PrivateAdFlow.vue'
  )
)
const PrivateAdEventHook = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "2-private-ad" */ '@components/PrivateAdEventHook/PrivateAdEventHook.vue'
  )
)

// My Biltorvet
const MyBiltorvetView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "4-my-biltorvet" */ '@components/Views/MyBiltorvetView/MyBiltorvetView.vue'
  )
)
const EditUserPage = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "4-my-biltorvet" */ '@components/User/Edit/EditUserPage.vue'
  )
)
const MySearchAgents = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "4-my-biltorvet" */ '@components/MyBiltorvet/MySearchAgents.vue'
  )
)
const MyAds = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "4-my-biltorvet" */ '@components/MyBiltorvet/MyAds.vue'
  )
)
const SolgtComWidget = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "4-my-biltorvet" */ '@components/SolgtComWidget/SolgtComWidget.vue'
  )
)

// Pro Search
const ProSearch = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "5-pro-search" */ '@components/ProSearch/ProSearch.vue'
  )
)

// // Search Agent Delete Page
const SearchAgentDeletePage = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "6-search-agent-delete" */ '@components/SearchAgentDelete/SearchAgentDeletePage.vue'
  )
)

// Results
const SearchResultsContainer = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "7-results" */ '@components/SearchResultsContainer/SearchResultsContainer.vue'
  )
)

// Company Search
const CompanySearchView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "8-company-search" */ '@components/Views/CompanySearchView/CompanySearchView.vue'
  )
)
const CompanySearch = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "8-company-search" */ '@components/CompanySearch/CompanySearch.vue'
  )
)
const CompanySearchResults = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "8company-search" */ '@components/CompanySearchResults/CompanySearchResults.vue'
  )
)

// Article Search
const ArticleSearchView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "9-article-search" */ '@components/Views/ArticleSearchView/ArticleSearchView.vue'
  )
)
const ArticleSearch = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "9-article-search" */ '@components/ArticleSearch/ArticleSearch.vue'
  )
)

// Article Detail
const ArticleDetailView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "10-article-detail" */ '@components/Views/ArticleDetailView/ArticleDetailView.vue'
  )
)

// Company Detail
const CompanyDetailView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "11-company-detail" */ '@components/Views/CompanyDetailView/CompanyDetailView.vue'
  )
)

// Seo
const SeoView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "12-seo" */ '@components/Views/SeoView/SeoView.vue'
  )
)

// Make Importer
const MakeImporterView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "13-make-importer" */ '@components/Views/MakeImporterView/MakeImporterView.vue'
  )
)

// Cookie Policy
const CookiePolicyView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "14-cookie-policy" */ '@components/Views/CookiePolicyView/CookiePolicyView.vue'
  )
)

// Terms And Conditions
const TermsAndConditionsView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "15-terms-and-conditions" */ '@components/Views/TermsAndConditionsView/TermsAndConditionsView.vue'
  )
)

// Ad Missing
const AdMissingView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "16-ad-missing" */ '@components/Views/AdMissingView/AdMissingView.vue'
  )
)

// Static Page
const StaticPageView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "17-static-page" */ '@components/Views/StaticPageView/StaticPageView.vue'
  )
)

// LicensePlate Lookup Page
const LicensePlateView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "18-license-plate" */ '@components/Views/LicensePlateView/LicensePlateView.vue'
  )
)

// About us Page
const AboutView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "19-about" */ '@components/Views/AboutView/AboutView.vue'
  )
)

// Styleguide Page
const StyleguideView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "20-styleguide" */ '@components/Views/StyleguideView/StyleguideView.vue'
  )
)

// Requirement Search
const RequirementSearchView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "21-requirement-search" */ '@components/Views/RequirementSearchView/RequirementSearchView.vue'
  )
)
const RequirementSearchButton = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "21-requirement-search" */ '@components/RequirementSearch/RequirementSearchButton.vue'
  )
)
const RequirementSearch = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "21-requirement-search" */ '@components/RequirementSearch/RequirementSearch.vue'
  )
)

// Editorial Page
const EditorialPageView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "22-editorial-page" */ '@components/Views/EditorialPageView/EditorialPageView.vue'
  )
)

// Rental companies
const RentalCompaniesView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "23-rental" */ '@components/Views/RentalCompaniesView/RentalCompaniesView.vue'
  )
)
const RentalCompanySearchResults = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "23-rental" */ '@components/RentalCompanySearchResults/RentalCompanySearchResults.vue'
  )
)

// Finance Partners Page
const FinancePartnersView = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "24-finance-partners" */ '@components/Views/FinancePartnersView/FinancePartnersView.vue'
  )
)

// App Landing Page
const AppCallToActions = defineAsyncComponent(() =>
  import('@components/AppCallToActions/AppCallToActions.vue')
)

const AppBannerAd = defineAsyncComponent(() =>
  import('@components/AppBannerAd/AppBannerAd.vue')
)

// Global
const CardAd = defineAsyncComponent(() =>
  import('@components/CardAd/CardAd.vue')
)
app.component('cardad', CardAd)

const CardAdSlider = defineAsyncComponent(() =>
  import('@components/CardAdSlider/CardAdSlider.vue')
)
app.component('cardadslider', CardAdSlider)

const ShortcutBanner = defineAsyncComponent(() =>
  import('@components/ShortcutBanner/ShortcutBanner.vue')
)
app.component('shortcutbanner', ShortcutBanner)

import Newsletter from '@components/Newsletter/Newsletter.vue'
app.component('newsletter', Newsletter)

// Global (menu)
import Menu from '@components/Header/Menu.vue'
app.component('vuemenu', Menu)

import LoginPage from '@components/User/Login/LoginPage.vue'
app.component('loginpage', LoginPage)

import ResetPasswordPage from '@components/User/ResetPassword/ResetPasswordPage.vue'
app.component('resetpasswordpage', ResetPasswordPage)

// list of special components that need to be registered here
import Overlay from '@shared/Overlay/Overlay.vue'
app.component('Overlay', Overlay)

import ListHorizontalContainer from '@search/ListHorizontal/ListHorizontalContainer.vue'
app.component('ListHorizontalContainer', ListHorizontalContainer)

import OverlayProductType from '@shared/OverlayProductType/OverlayProductType.vue'
app.component('OverlayProductType', OverlayProductType)

/************************/
/* Mounting components  */
/************************/
// Frontpage
app.component('frontpageview', FrontpageView)
app.component('search', Search)
app.component('licenseplatelookup', LicensePlateLookup)
app.component('articlecontainereventhook', ArticleContainerEventHook)

// Ad
app.component('adview', AdView)
app.component('subheaderad', SubHeaderAd)
app.component('subheadercompany', SubHeaderCompany)
app.component('gallery', Gallery)
app.component('gallery2', Gallery2)
app.component('galleryactions', GalleryActions)
app.component('contactbutton', ContactButton)
app.component('contacteventhook', ContactEventHook)
app.component('adeventhook', AdEventHook)
app.component('financesantander', FinanceSantander)
app.component('financetoyota', FinanceToyota)
app.component('adpricefinancebutton', AdPriceFinanceButton)
app.component('adpriceinsurancebutton', AdPriceInsuranceButton)
app.component('exchangepricewidget', ExchangePriceWidget)
app.component('onlinesalesbutton', OnlineSalesButton)
app.component('insurancesamlino', InsuranceSamlino)
app.component('insurancecodan', InsuranceCodan)
app.component('insurancecodanexpanded', InsuranceCodanExpanded)
app.component('insurancetechno', InsuranceTechno)
app.component('insurancesemler', InsuranceSemler)
app.component('accordioncontentcontactbutton', AccordionContentContactButton)
app.component('description', Description)

// Private Ad
app.component('privateadview', PrivateAdView)
app.component('privateadflow', PrivateAdFlow)
app.component('privateadeventhook', PrivateAdEventHook)

// My Biltorvet
app.component('mybiltorvetview', MyBiltorvetView)
app.component('edituserpage', EditUserPage)
app.component('mysearchagents', MySearchAgents)
app.component('myads', MyAds)
app.component('solgtcomwidget', SolgtComWidget)

// Pro Search
app.component('prosearch', ProSearch)

// Search Agent Delete Page
app.component('searchagentdeletepage', SearchAgentDeletePage)

// Results
app.component('searchresultscontainer', SearchResultsContainer)

// Company Search
app.component('companysearchview', CompanySearchView)
app.component('companysearch', CompanySearch)
app.component('companysearchresults', CompanySearchResults)

// Article Search
app.component('articlesearchview', ArticleSearchView)
app.component('articlesearch', ArticleSearch)

// Article Detail
app.component('articledetailview', ArticleDetailView)

// Company Detail
app.component('companydetailview', CompanyDetailView)

// Seo
app.component('seoview', SeoView)

// MakeImporterView
app.component('makeimporterview', MakeImporterView)

// Cookie Policy
app.component('cookiepolicyview', CookiePolicyView)

// Terms And Conditions
app.component('termsandconditionsview', TermsAndConditionsView)

// Ad Missing
app.component('admissingview', AdMissingView)

// Static Page
app.component('staticpageview', StaticPageView)

// LicensePlate Lookup Page
app.component('licenseplateview', LicensePlateView)

// About Us Page
app.component('aboutview', AboutView)

// Styleguide
app.component('styleguideview', StyleguideView)

// Editorial Page
app.component('editorialpageview', EditorialPageView)

// Requirement Search
app.component('requirementsearchview', RequirementSearchView)
app.component('requirementsearchbutton', RequirementSearchButton)
app.component('requirementsearch', RequirementSearch)

// Rental companies page
app.component('rentalcompaniesview', RentalCompaniesView)
app.component('rentalcompanysearchresults', RentalCompanySearchResults)

// Finance Partners page
app.component('financepartnersview', FinancePartnersView)

// App Landing Page
app.component('appcalltoactions', AppCallToActions)
app.component('appbannerad', AppBannerAd)

/*****************************/
/*     General scripting     */
/*****************************/
import { activateSingleSelect } from '@/functions'
import { initListener } from '@/components/Header/Header'

window.addEventListener('DOMContentLoaded', () => {
  activateSingleSelect(document.getElementsByClassName('footer')[0])
  initListener()
})

// Handle image errors for articles and ads
window.handleImageError = element => {
  element.removeAttribute('alt')
  element.classList.add('fallback-image')
}

// Scripting for accordions
window.closeAllAccordions = () => {
  var checks = [].slice.call(
    document.getElementsByClassName('accordion-panel__list--check')
  )
  for (let index = 0; index < checks.length; index++) {
    const check = checks[index]
    if (check.checked) {
      check.click()
    }
  }
}

window.openAllAccordions = () => {
  var checks = [].slice.call(
    document.getElementsByClassName('accordion-panel__list--check')
  )
  for (let index = 0; index < checks.length; index++) {
    const check = checks[index]
    if (!check.checked) {
      check.click()
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  var checks = [].slice.call(
      document.getElementsByClassName('accordion-panel__list--check')
    ),
    accordionClose = document.getElementsByClassName('js-accordion-close')[0],
    accordionOpen = document.getElementsByClassName('js-accordion-open')[0]

  var noOfOpen = checks.filter(checkbox => {
    return checkbox.checked
  })

  if (checks.length > 0) {
    for (let index = 0; index < checks.length; index++) {
      const check = checks[index]
      check.addEventListener('change', event => {
        if (event.target.checked) {
          noOfOpen++
        } else {
          noOfOpen--
        }

        if (noOfOpen >= Math.ceil(checks.length / 2)) {
          accordionClose.style['display'] = 'block'
          accordionOpen.style['display'] = 'none'
        } else {
          accordionClose.style['display'] = 'none'
          accordionOpen.style['display'] = 'block'
        }
      })
    }

    checks[0].click()
  }
})

/*****************************/
/*     Track print event     */
/*****************************/
import { LogPrint } from './events'

// When print dialog is closed, not knowing if print or cancel
// we could use onbeforeprint which is just before the print dialog
// has been opened, but still not knowing if print or cancel
window.onbeforeprint = function () {
  app.mitt.emit('print')
  LogPrint()
}

/******************************/
/*     Safari bfcache fix     */
/******************************/
import spinner from '@/spinner'
window.onpageshow = function (event) {
  if (event.persisted) {
    spinner.stop()
  }
}

app.mount('#app')
